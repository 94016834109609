import Swiper, { Navigation, Scrollbar, Pagination } from 'swiper';

export default function tbxHandleNewsFeed() {
	const sections = document.querySelectorAll('.cu-section__news-feed');

	if ( sections !== null && sections.length > 0 ) {
		sections.forEach( section => {
			new Swiper( section.querySelector('.swiper-container'), {
				modules: [ Navigation, Scrollbar ],
				slidesPerView: 'auto',
				spaceBetween: 25,
				navigation: {
					prevEl: section.querySelector('.swiper-button-prev'),
					nextEl: section.querySelector('.swiper-button-next')
				},
				scrollbar: {
	                el: section.querySelector('.swiper-scrollbar'),
	                draggable: true,
	                dragSize: 180
	            },
			});

			new Swiper( section.querySelector('.swiper-container.eventdaterotator'), {
				modules: [ Navigation, Pagination ],
				slidesPerView: '1',
				spaceBetween: 60,
				touchEventsTarget: 'wrapper',
				touchReleaseOnEdges: true,
				breakpoints: {
					320: {
						slidesPerView:1,
						spaceBetween:50
					},
					// when window width is >= 320px
					600: {
					  slidesPerView: 2,
					  spaceBetween: 20
					},
					// when window width is >= 480px
					900: {
					  slidesPerView: 3,
					  spaceBetween: 30
					},
					// when window width is >= 640px
					1400: {
					  slidesPerView: 4,
					  spaceBetween: 40
					}
				  },
				navigation: {
					prevEl: section.querySelector('.swiper-button-prev'),
					nextEl: section.querySelector('.swiper-button-next')
				},
				pagination: {
					el: section.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				  },
			});

			new Swiper( section.querySelector('.swiper-container.rotateposts'), {
				modules: [ Navigation, Pagination ],
				slidesPerView: 'auto',
				spaceBetween: 25,
				breakpoints: {
					320: {
						slidesPerView:'auto',
						spaceBetween:50	
					},
					// when window width is >= 320px
					600: {
					  slidesPerView: 'auto',
					  spaceBetween: 35
					},
				  },
				navigation: {
					prevEl: section.querySelector('.swiper-button-prev'),
					nextEl: section.querySelector('.swiper-button-next')
				},
				pagination: {
					el: section.querySelector('.swiper-pagination'),
					type: 'bullets',
				  },
			});
			



			
		});
	}
}